import React, { useContext, useEffect } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import axios from "./Utils/Axios";
import { AuthContext } from "./Context/AuthContext";
import Dashboard from "./Pages/Dashboard/Dashboard";
import Header from "./Pages/Templates/Header";
import SideBar from "./Pages/Templates/SideBar";
import Footer from "./Pages/Templates/Footer";
import Login from "./Pages/Login/Login";
import Profile from "./Pages/Login/Profile";
import Categories from "./Pages/Catalog/Categories";
import Services from "./Pages/Services/Services";
import Users from "./Pages/UserManagement/Users";
import Roles from "./Pages/UserManagement/Roles";
import AddRole from "./Pages/UserManagement/AddRole";
import AddUser from "./Pages/UserManagement/AddUser";
import EditUser from "./Pages/UserManagement/EditUser";
import EditRole from "./Pages/UserManagement/EditRole";
import EditCategory from "./Pages/Catalog/EditCategory";
import EditService from "./Pages/Services/EditService";
import AddService from "./Pages/Services/AddService";
import AddCategory from "./Pages/Catalog/AddCategory";
import Enquiry  from "./Pages/Enquiries/Enquiry";
import Log from "./Pages/Logs/Log";
import VisaEnquiry from "./Pages/VisaEnquiry/VisaEnquiries";
import BlogCategory from "./Pages/Blogs/BlogCategories";
import EditBlogCategory from "./Pages/Blogs/EditBlogCategory";
import AddBlogCategory from "./Pages/Blogs/AddBlogCategory";
import Blog from "./Pages/Blogs/Blogs";
import EditBlog from "./Pages/Blogs/EditBlog";
import AddBlog from "./Pages/Blogs/AddBlog";
import EnquiryDetail from "./Pages/Enquiries/EnquiryDetail";
import VisaEnquiryDetail from "./Pages/VisaEnquiry/VisaEnquiryDetails";
import EditPageSection from "./Pages/PageSection/EditPageSection";
import GoldenVisaEligibilities from "./Pages/GoldenVisaEligibility/GoldenVisaEligibilities";
function App() {
  const { role, setRole, setPermissions } = useContext(AuthContext);

  useEffect(() => {
    if (!role) {
      axios(`admin/user_permissions`, {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: "Bearer " + JSON.parse(localStorage.getItem("data")),
        },
      })
        .then((res) => {
          if (res.status === 200) {
            setRole(res.data.data.role);
            setPermissions(res.data.data.permissions);
          } else {
          }
        })
        .catch((err) => {
          if (err.response) {
            console.log(err.response);
          } else if (err.request) {
            console.log(err.request);
          } else {
            console.log(err);
          }
        });
    }
  });
  return (
    <div id="wrapper">
      <Switch>
        <LoginSideBar />
      </Switch>
      <Switch>
        <LoginHeader />
      </Switch>

      <div id="content-wrapper">
        <div id="content">
          <Switch>
            <Route path="/login" exact component={Login} />
            <ProtectedRoute path="/" exact component={Dashboard} />
            <ProtectedRoute path="/profile" exact component={Profile} />
            <ProtectedRoute path="/categories" exact component={Categories} />
            <ProtectedRoute path="/services" exact component={Services} />
            <ProtectedRoute path="/users" exact component={Users} />
            <ProtectedRoute path="/add-user" exact component={AddUser} />
            <ProtectedRoute path="/roles" exact component={Roles} />
            <ProtectedRoute path="/add-role" exact component={AddRole} />
            <ProtectedRoute path="/edit-role/:id" exact component={EditRole} />
            <ProtectedRoute path="/edit-user/:id" exact component={EditUser} />
            <ProtectedRoute path="/edit-category/:id" exact component={EditCategory} />
            <ProtectedRoute path="/edit-service/:id" exact component={EditService} />
            <ProtectedRoute path="/add-service" exact component={AddService} />
            <ProtectedRoute path="/add-category" exact component={AddCategory} />
            <ProtectedRoute path="/enquiries" exact component={Enquiry} />
            <ProtectedRoute path="/enquiry-detail/:id" exact component={EnquiryDetail} />
            <ProtectedRoute path="/visa-enquiries" exact component={VisaEnquiry} />
            <ProtectedRoute path="/visa-enquiry-detail/:id" exact component={VisaEnquiryDetail} />
            <ProtectedRoute path="/golden-visa-eligibility" exact component={GoldenVisaEligibilities} />
            <ProtectedRoute path="/blog_category" exact component={BlogCategory} />
            <ProtectedRoute path="/edit-blog_category/:id" exact component={EditBlogCategory} />
            <ProtectedRoute path="/add-blog_category" exact component={AddBlogCategory} />
            <ProtectedRoute path="/blogs" exact component={Blog} />
            <ProtectedRoute path="/edit-blogs/:id" exact component={EditBlog} />
            <ProtectedRoute path="/add-blog" exact component={AddBlog} />
            <ProtectedRoute path="/log" exact component={Log} />
            <ProtectedRoute
              path="/edit-page-section/:id"
              exact
              component={EditPageSection}
            />

          </Switch>
        </div>
      </div>
      <Switch>
        <LoginFooter />
      </Switch>
    </div>
  );
}

const ProtectedRoute = ({ component: Component, path }) => {
  return (
    <Route
      path={path}
      render={(props) => {
        return localStorage.getItem("data") ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              refresh: true,
              state: {
                prevLocation: path,
                error: "You need to login first!",
              },
            }}
          />
        );
      }}
    />
  );
};
const LoginHeader = () => {
  return localStorage.getItem("data") ? <Header rand={Math.random()} /> : "";
};
const LoginSideBar = () => {
  return localStorage.getItem("data") ? <SideBar /> : "";
};
const LoginFooter = () => {
  return localStorage.getItem("data") ? <Footer /> : "";
};

export default App;
