import React, { useEffect, useState } from "react";
import axios from "../../Utils/Axios";
import RingLoader from "react-spinners/RingLoader";
import LoadingOverlay from "react-loading-overlay-ts";
import { Link } from "react-router-dom";
import Breadcrumbs from '@mui/material/Breadcrumbs';
import BLink from '@mui/material/Link';
import { useAlert } from "react-alert";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Box from "@mui/material/Box";
import { ErrorHandler } from "../../ui/ErrorHandler";
import { DateRangePicker } from "react-dates";
import { makeStyles } from "@mui/styles";
import moment from "moment";
import SelectSearch from 'react-select-search';
import "./Enquiry.css";
import zIndex from "@mui/material/styles/zIndex";
const useStyles = makeStyles((theme) => ({

    PresetDateRangePicker_panel: {
        padding: "0 22px 11px 22px",
    },

    PresetDateRangePicker_button: {
        position: "relative",
        height: "100%",
        textAlign: "center",
        background: "none",
        border: `2px solid #138496 `,
        color: "#138496",
        padding: "4px 12px",
        marginRight: 8,
        font: "inherit",
        fontWeight: 700,
        lineHeight: "normal",
        overflow: "visible",
        boxSizing: "border-box",
        cursor: "pointer",

        ":active": {
            outline: 0,
        },
    },

    PresetDateRangePicker_button__selected: {
        position: "relative",
        height: "100%",
        textAlign: "center",
        background: "none",
        border: `2px solid #138496 `,
        color: "#138496",
        padding: "4px 12px",
        marginRight: 8,
        font: "inherit",
        fontWeight: 700,
        lineHeight: "normal",
        overflow: "visible",
        boxSizing: "border-box",
        cursor: "pointer",

        ":active": {
            outline: 0,
        },
        color: "white",
        background: "#138496",
    },
}));


function Enquiry() {


    const [loading, setLoading] = useState(false);
    const [Enquiry, setEnquiry] = useState([]);
    const [statuses, setStatuses] = useState([{ value: 0, name: 'Select Status' }]);
    const [users, setUsers] = useState([{ value: 0, name: 'Select User' }]);;
    const [status, setStatus] = useState(0);
    const [assignedTo, setAssignedTo] = useState(0);
    const [per_page, setPer_page] = useState(10);
    const [total, setTotal] = useState(0);
    const [keyword, setSearch] = useState();
    const [page, setPage] = useState(1);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [type, setType] = useState(0);
    const [types, setTypes] = useState([{ value: 0, name: 'Select Page Type' }, { value: 1, name: 'Service Page' }, { value: 2, name: 'Chat Bot' }, { value: 3, name: 'Contact page' }, { value: 4, name: 'External Site' }]);
    const today = moment();
    const [focusedInput, setFocusedInput] = useState(null);
    const classes = useStyles();

    const typeLabels = {
        1: "Service Page",
        2: "Bot Page",
        3: "Contact Page",
        4: "Golden Visa Site",
    };

    const yesterday = moment().add(-1, "day");
    const presets = [
        {
            text: "Today",
            start: today,
            end: today,
        },
        {
            text: "Yesterday",
            start: yesterday,
            end: yesterday,
        },
        {
            text: "This Week",
            start: moment().add(-1, "week"),
            end: today,
        },
        {
            text: "This Month",
            start: moment().add(-1, "month"),
            end: today,
        },
        {
            text: "This Year",
            start: moment().add(-1, "year"),
            end: today,
        },
    ];

    const onDatesChange = ({ startDate, endDate }) => {
        setStartDate(startDate);
        setEndDate(endDate);
    };

    function isSameDay(a, b) {
        if (!moment.isMoment(a) || !moment.isMoment(b)) return false;
        return (
            a.date() === b.date() && a.month() === b.month() && a.year() === b.year()
        );
    }



    function renderDatePresets() {
        return (
            <div className={classes.PresetDateRangePicker_panel}>
                {presets.map(({ text, start, end }) => {
                    const isSelected =
                        isSameDay(start, startDate) && isSameDay(end, endDate);
                    return (
                        <button
                            key={text}
                            className={
                                isSelected
                                    ? classes.PresetDateRangePicker_button__selected
                                    : classes.PresetDateRangePicker_button
                            }
                            type="button"
                            onClick={() => onDatesChange(start, end)}
                        >
                            {text}
                        </button>
                    );
                })}
            </div>
        );
    }

    const alert = useAlert();
    useEffect(() => {
        getEnquiry();
    }, [page, per_page, keyword, startDate, endDate, type, status, assignedTo]);
    useEffect(() => {
        getUsers();
        getStatuses();
    }, []);
    const getEnquiry = () => {
        setLoading(true);
        let formdata = new FormData();
        formdata.append("page", page);
        formdata.append("per_page", per_page);

        const qs = require('qs');
        let filters = {
            $or: [
                { name: { $contains: keyword } },
                { email: { $contains: keyword } },
                { enquiry_id: { $contains: keyword } },
            ]
        };

        if (startDate && endDate) {
            // If start date and end date are provided, use 'between' for created_at
            filters.$and = filters.$and || [];
            filters.$and.push({ created_at: { $between: [startDate.format('YYYY-MM-DD'), endDate.format('YYYY-MM-DD')] } });
        } else if (startDate) {
            // If only start date is provided, use 'contains' for created_at
            filters.$and = filters.$and || [];
            filters.$and.push({ created_at: { $contains: startDate.format('YYYY-MM-DD') } });
        }

        // Retain other filters if they exist
        if (type && type >= 0) {
            filters.$and = filters.$and || [];
            filters.$and.push({ type: { $eq: type } });
        }
        if (status && status > 0) {
            filters.$and = filters.$and || [];
            filters.$and.push({ status_id: { $eq: status } });
        }
        if (assignedTo && assignedTo > 0) {
            filters.$and = filters.$and || [];
            filters.$and.push({ assigned_to: { $eq: assignedTo } });
        }

        formdata.append("filters", JSON.stringify(filters));

        // Retrieve token from localStorage
        const tokenData = localStorage.getItem("data");
        const token = tokenData ? JSON.parse(tokenData) : null;
        if (!token) {
            alert("Authorization token is missing!");
            return;
        }

        // Use the relative API endpoint (adjust if necessary)
        axios.post("admin/enquiries", formdata, {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "multipart/form-data"
            }
        })
            .then((response) => {
                if (response.status === 200) {
                    setLoading(false);
                    setEnquiry(response.data.data.data);
                    setTotal(response.data.data.last_page);
                } else {
                    setLoading(false);
                    ErrorHandler(alert, response.status, response.data.message);
                }
            })
            .catch(({ response }) => {
                setLoading(false);
                ErrorHandler(alert, response.status, response.data.message);
            });
    };

    const exportEnquiries = () => {
        let formdata = new FormData();
        formdata.append("export", true);
        formdata.append("page", page);
        formdata.append("per_page", per_page);

        let filters = {
            $or: [
                { name: { $contains: keyword } },
                { email: { $contains: keyword } },
                { enquiry_id: { $contains: keyword } },
            ]
        };

        if (startDate && endDate) {
            filters.$and = filters.$and || [];
            filters.$and.push({ created_at: { $between: [startDate.format('YYYY-MM-DD'), endDate.format('YYYY-MM-DD')] } });
        } else if (startDate) {
            filters.$and = filters.$and || [];
            filters.$and.push({ created_at: { $contains: startDate.format('YYYY-MM-DD') } });
        }

        if (type && type >= 0) {
            filters.$and = filters.$and || [];
            filters.$and.push({ type: { $eq: type } });
        }
        if (status && status > 0) {
            filters.$and = filters.$and || [];
            filters.$and.push({ status_id: { $eq: status } });
        }
        if (assignedTo && assignedTo > 0) {
            filters.$and = filters.$and || [];
            filters.$and.push({ assigned_to: { $eq: assignedTo } });
        }

        formdata.append("filters", JSON.stringify(filters));

        const token = JSON.parse(localStorage.getItem("data"));
        axios.post("admin/enquiries", formdata, {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "multipart/form-data",
            },
            responseType: 'blob',
        })
            .then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'enquiries.csv');
                document.body.appendChild(link);
                link.click();
                link.remove();
            })
            .catch(({ response }) => {
                ErrorHandler(alert, response.status, response.data.message);
            });
    };
    const getStatuses = () => {
        axios("admin/all_statuses", {
            method: "POST",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                Authorization: "Bearer " + JSON.parse(localStorage.getItem("data")),
            },
        })
            .then((response) => {
                if (response.status === 200) {
                    setStatuses(prevState => [...prevState, ...response.data.data]);
                } else {
                    setLoading(false);
                    ErrorHandler(alert, response.status, response.data.message)
                }
            })
            .catch(({ response }) => {
                setLoading(false);
                ErrorHandler(alert, response.status, response.data.message)
            });
    };

    const getUsers = () => {
        axios("admin/all_admins", {
            method: "POST",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                Authorization: "Bearer " + JSON.parse(localStorage.getItem("data")),
            },
        })
            .then((response) => {
                if (response.status === 200) {
                    setUsers(prevState => [...prevState, ...response.data.data]);
                } else {
                    setLoading(false);
                    ErrorHandler(alert, response.status, response.data.message)
                }
            })
            .catch(({ response }) => {
                setLoading(false);
                ErrorHandler(alert, response.status, response.data.message)
            });
    };
    return (
        <div>
            <div class="container-fluid">
                <div role="presentation">
                    <Breadcrumbs aria-label="breadcrumb">
                        <BLink underline="hover" color="inherit" href="/">
                            Home
                        </BLink>
                        <BLink
                            underline="hover"
                            color="text.primary"
                            aria-current="page"
                            href="/Enquiry"
                        >
                            Enquiry
                        </BLink>
                    </Breadcrumbs>
                </div>
                <div class="mb-4">
                    <div class="card-body">
                        <div className="d-flex justify-content-between align-items-center mb-2">
                            <h1 className="h2 text-gray-800">Enquiries</h1>
                            <button onClick={exportEnquiries} className="btn btn-primary">
                                Export CSV
                            </button>
                        </div>
                        <hr />
                        <div class="container-fluid">
                            <div className="mt-2">
                                <div className="row mb-3">
                                    <div className="col-8">
                                        <input
                                            type="text"
                                            className="form-control form-control-lg "
                                            placeholder="Search.... "
                                            onChange={(e) => setSearch(e.target.value)}

                                        />
                                    </div>
                                    <div className="col-4 z-index-9">
                                        <DateRangePicker
                                            presets={presets}
                                            renderCalendarInfo={renderDatePresets}
                                            showClearDates={true}
                                            startDate={startDate}
                                            startDateId="s_id"
                                            endDate={endDate}
                                            endDateId="e_id"
                                            isOutsideRange={(day) => moment().diff(day) < 0}
                                            onDatesChange={({ startDate, endDate }) => {
                                                setStartDate(startDate);
                                                setEndDate(endDate);
                                            }}
                                            focusedInput={focusedInput}
                                            onFocusChange={(e) => setFocusedInput(e)}
                                            displayFormat="YYYY-MM-DD"
                                        />
                                    </div>
                                    <br></br>
                                    <br></br>
                                    <br></br>
                                    <div className="col-4">
                                        <SelectSearch options={types} id="type" name="type" onChange={(e) =>
                                            setType(e)
                                        } value={type} search placeholder="Select  Type" />
                                    </div>
                                    <div className="col-4">
                                        <SelectSearch options={statuses} id="status" name="status" onChange={(e) =>
                                            setStatus(e)
                                        } value={status} search placeholder="Select  Status" />
                                    </div>
                                    <div className="col-4">
                                        <SelectSearch options={users} id="assigned_to" name="assigned_to" onChange={(e) =>
                                            setAssignedTo(e)
                                        } value={assignedTo} search placeholder="Select User" />
                                    </div>
                                </div>
                                <div className="table-responsive">
                                    <LoadingOverlay
                                        active={loading}
                                        styles={{
                                            overlay: (base) => ({
                                                ...base,
                                                background: "rgba(255, 255, 255, 0.9)",
                                            }),
                                            spinner: (base) => ({
                                                ...base,
                                                width: "75px",
                                                "& svg circle": {
                                                    stroke: "rgba(0, 0, 0, 0.8)",
                                                },
                                            }),
                                        }}
                                        spinner={<RingLoader loading={loading} color="#36d7b7" />}
                                        text="..."
                                    >
                                        <table className="table table-bordered  ">
                                            <thead>
                                                <tr>
                                                    <th>No</th>
                                                    <th>Enquiry Id</th>
                                                    <th>Source</th>
                                                    <th>Customer name</th>
                                                    <th>Email</th>
                                                    <th>Phone</th>
                                                    <th>Service</th>
                                                    <th>Assigned To</th>
                                                    <th>Status</th>
                                                    <th>Created At</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {Enquiry.length > 0 ? Enquiry.map((value, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            <td>{index + 1}</td>
                                                            <td>{value.enquiry_id}</td>
                                                            <td>{typeLabels[value.type] || value.type}</td>
                                                            <td>{value.name}</td>
                                                            <td>{value.email}</td>
                                                            <td>{value.phone}</td>
                                                            <td>
                                                                {value.service && value.service.name}
                                                            </td>
                                                            <td>
                                                                {value.admin && value.admin.first_name}
                                                            </td>
                                                            <td>
                                                                {value.status && value.status.name}
                                                            </td>
                                                            <td>
                                                                {value.created_at}
                                                            </td>
                                                            <td>
                                                                <Link to={'/enquiry-detail/' + value.id}><button className="btn btn-sm btn-dark" title="Edit"><i className="fi fi-rr-file-edit"></i></button></Link>
                                                                {/* <button className="btn btn-sm btn-danger" title="Delete" onClick={() => deleteCategory(value.id)}><i className="fi fi-rr-trash"></i></button> */}
                                                            </td>


                                                            {/* <td className="space-evenly">


                                                            </td> */}
                                                        </tr>
                                                    );
                                                }) : (<td colSpan={10} className="text-center"> No Enquiry</td>)}
                                            </tbody>
                                        </table>
                                    </LoadingOverlay>
                                    <div>
                                        <Box p={2} display="flex" justifyContent="center">
                                            <Stack spacing={2}>
                                                <Pagination
                                                    color="secondary"
                                                    count={total}
                                                    page={page}
                                                    onChange={(event, value) => {
                                                        setPage(value);
                                                    }}
                                                />
                                            </Stack>
                                            <Stack spacing={2}>
                                                <Select
                                                    value={per_page}
                                                    onChange={(e) => setPer_page(e.target.value)}
                                                >
                                                    <MenuItem value={10}>10</MenuItem>
                                                    <MenuItem value={50}>50</MenuItem>
                                                    <MenuItem value={100}>100</MenuItem>
                                                </Select>
                                            </Stack>
                                        </Box>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
}
export default Enquiry;
